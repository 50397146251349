import './logo.css';
import LOGO from '../../../assets/images/logo_gradient.svg';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

export function Logo({style}){
    return(
        <div className='brand-logo' style={style}>
            <img style={{height: "100%", aspectRatio: '24 / 25'}} src={LOGO} alt="logo"></img>
        </div>
    );
}

export function FullLogo({style}){
    const [size, setSize] = useState();
    const [gap, setGap] = useState();
    const ref = useRef();

    useEffect(() => {
        setSize(ref.current.clientHeight/1.03);
        setGap(ref.current.clientHeight/10)
    }, [ref, style])

    return(
        <div className='brand-logo' style={{ gap: gap ,...style}}>
            <img style={{height: "100%", aspectRatio: '24 / 25'}} ref={ref} src={LOGO} alt="logo"></img>
            <div className='brand-logo-text' style={{fontSize: size }}>Ambient Sphere</div>
        </div>
    );
}

function BrandTag({children}) {
    return (
        <div className='brand-logo-tag'>
            { children }
        </div>
    );
}

export function FullLogoAlpha({style}){
    const [size, setSize] = useState();
    const [gap, setGap] = useState();
    const ref = useRef();

    useEffect(() => {
        setSize(ref.current.clientHeight/1.03);
        setGap(ref.current.clientHeight/10)
    }, [ref, style])

    return(
        <div className='brand-logo' style={{ gap: gap ,...style}}>
            <img style={{height: "100%", aspectRatio: '24 / 25'}} ref={ref} src={LOGO} alt="logo"></img>
            <div className='brand-logo-text' style={{fontSize: size }}>Ambient Sphere</div>
            <BrandTag>Alpha</BrandTag>
        </div>
    );
}