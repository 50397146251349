import './top.css';
import { PrimaryButton } from '../../components/shared/buttons/buttons';
import TOP_IMAGE from '../../assets/images/subscription_plans_landing.webp';
import useEventTracker from '../../hooks/analytics/useEventTracker';

function Background({ numColumns}) {
    const style = {
        height: numColumns > 3 ? "calc(100vw * (1440/3440))" : "80vh"
    };
    return (
        <div className='background-container' style={style}>
            <div className='background-image' style={{backgroundImage: "url(" + TOP_IMAGE + ")"}}/>
            <div className='background-gradient'/>
        </div>
    );
}

export default function TopSection({ numColumns, actionCallback, children }) {
    const titleSize = numColumns >= 4 ? "2.7rem" : 
        numColumns === 3 ? "2.2rem" : 
        numColumns === 2 ? "2.2rem" : 
        "1.7rem";
    const subtitleSize = numColumns >= 4 ? "1.1rem" : 
        numColumns === 3 ? "1.1rem" : 
        numColumns === 2 ? "1.1rem" : 
        "0.9rem";
    const titleWrapperMaxWidth = "calc(" + (numColumns > 2 ? 2 : numColumns) + " * var(--lay-column-width))";
    const contentWrapperMaxWidth = "calc(" + numColumns +" * var(--lay-column-width))";
    const plans_hero_button = useEventTracker({id: "plans_hero_button"});
    return (
        <div className='top-section'>
            <Background numColumns={numColumns}/>
            <div className='wrapper' style={{maxWidth: titleWrapperMaxWidth}}>
                <div className='title'>
                    <div style={{flexGrow: 1}}/>
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', zIndex: -1, inset: 0, borderRadius: '25%', background: 'var(--col-bg-dp0)', filter: 'blur(16px) opacity(40%)'}}/>
                        <div style={{
                            margin: "8px 0px",
                            font: "var(--font-head-h1)",
                            fontSize: titleSize}}>
                            <strong>Upgrade your Game Experience</strong>
                        </div>
                        <h4 style={{fontSize: subtitleSize}}>
                            Subscribe to Ambient Sphere now and give your story the soundtrack it deserves
                        </h4>
                        <div style={{margin: "var(--lay-gap-v-separate)"}}/>
                        <PrimaryButton onClick={(e) => { plans_hero_button(); actionCallback(e);} }>Take me to the plans</PrimaryButton>
                    </div>
                    <div style={{flexGrow: 1}}/>
                </div>
            </div>
            <div className='wrapper' style={{maxWidth: contentWrapperMaxWidth}}>
                <div>
                    { children }
                </div>
            </div>
        </div>
    );
}