import axios from "axios";
import { CMS_API_BASE_URL, CMS_API_KEY } from '../constants/api';

export const cmsApi = axios.create({
    baseURL: CMS_API_BASE_URL
});

export const cmsAssetLink = (field) => {
    if(field?.path)
        return (CMS_API_BASE_URL + "/storage/uploads" + field.path);
    return undefined;
}

cmsApi.interceptors.request.use(
    (config) => { 
        config.headers['api-key'] = CMS_API_KEY;
        return config;
    },
    (error) => { return Promise.reject(error); }
);

export function cmsGetItem(
    model,
    successHandler = (data) => { },
    unknownerrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    cmsApi.get(
        'api/content/item/' + model,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { unknownerrorHandler(); })
        .finally(finallyHandler);
    return controller;
}

export function getArtistList(
    successHandler = (artists) => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { }){
    const controller = new AbortController();
    const promise = cmsApi.get("api/content/items/artists")
        .then((r) => successHandler(
                r.data.map( artist => (
                    {
                        name: artist?.name, 
                        url: artist?.url, 
                        image: cmsAssetLink(artist?.image)
                    })
                )
            )
        )
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getLegalPageContent(type, 
    successHandler = (artists) => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { }){
    const controller = new AbortController();
    const promise = cmsApi.get("api/content/item/" + type)
        .then((r) => successHandler(r.data))
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return {
        abortController: controller,
        requestPromise: promise
    };
}