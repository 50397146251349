import { Link } from '@phosphor-icons/react';
import { SecondaryButton } from '../components/shared/buttons/buttons';
import { useNavigate } from 'react-router';
import LandingPageFooter from '../components/shared/landing-page-footer/landing_page_footer';
import useTitle from '../hooks/useTitle';
import './404.css';

export function Status404() {
    useTitle('Error 404 - Not found');
    const navigate = useNavigate();
    return (
        <div className="page-404">
            <div style={{flexGrow: 1}}/>
            <div className='page-width-container'>
                <div className='content'>
                    <div style={{textAlign: 'center'}}>
                        <h2><Link/>&nbsp;404</h2>
                        <h3>You are traveling on a lost path</h3>
                    </div>
                    <div style={{width: '100%', height: '1px', backgroundColor: 'var(--col-elem-separator-border)'}}/>
                    <div>
                        <div>
                            We could not locate what you are looking for.
                            This might be due to a broken link or a missing resource.
                        </div>
                        <div style={{height: 'var(--lay-gap-v-connect)'}}/>
                        <div>
                            Please try something else or <a href='mailto:contact@ambientsphere.com'>contact</a> us if you think this is a mistake.
                        </div>
                    </div>
                    <div style={{width: '100%', height: '1px', backgroundColor: 'var(--col-elem-separator-border)'}}/>
                    <SecondaryButton onClick={() => { navigate('/'); }}>Teleport Home</SecondaryButton>
                </div>
            </div>
            <div style={{flexGrow: 1}}/>
            <div style={{backgroundColor: 'var(--col-bg-dp3)'}}>
                <div className='page-width-container'>
                    <LandingPageFooter/>
                </div>
            </div>
        </div>
    );
}