import './video_section.css';
import DEMO_VIDEO from './../../assets/videos/AmbientSphereWebVideo_new.mp4'
import { useInView } from 'react-intersection-observer';
import useEventTracker from '../../hooks/analytics/useEventTracker';
import { useState } from 'react';
import useToggleTracker from '../../hooks/analytics/useToggleTracker';

export default function VideoSection({numColumns}){
    const vidWidth = numColumns * 320;
    const vidHeight = vidWidth * (9 / 16);
    const [muted, setMuted] = useState(true);
    const video_section = useToggleTracker({ id: "video_section"}, {on:"_enter", off: "_leave"}, 2);
    const video_volume = useToggleTracker({id: "video_volume"}, {on:"_on", off: "_off"}, 2);
    const video_pause = useEventTracker({id: "video_pause"});
    const video_play = useEventTracker({id: "video_play"});
    const onChange = (inView, entry) => {
        if(inView)
            video_section.start()
        else
            video_section.stop();
    }
    const { ref }  = useInView({
        threshold: 1, 
        onChange,
        delay: 2000
    })
    const onVolumeChange = (e) => {
        if((e.target.volume === 0 || e.target.muted) && !muted){
            video_volume.stop();
            setMuted(true);
        }
        else if(e.target.volume > 0 && !e.target.muted && muted){
            video_volume.start();
            setMuted(false);
        }
    }

    return(
        <div ref={ref} className="video-section" style={{height: vidHeight + 'px'}}>
            <video 
                width={vidWidth} 
                height={vidHeight} 
                onVolumeChange={onVolumeChange}
                onPlay={() => video_play()}
                onPause={() => video_pause()}
                muted autoPlay loop controls preload='metadata'>
                <source src={DEMO_VIDEO} type='video/mp4'/>
                This browser does not support HTML video.
            </video>
        </div>
    );
}