import CollapsableComponentIndex from "../../components/shared/collapsable-component-index/collapsable_component_index";
import useEventTracker from "../../hooks/analytics/useEventTracker";

function Separator() {
    return (
        <div style={{
            width: '100%',
            height: '1px',
            margin: 'var(--lay-gap-v-connect) 0px',
            backgroundColor: 'var(--col-elem-separator-border)'
        }}/>
    );
}

function QuestionBody({ children }) {
    return (
        <div style={{
            boxSizing: 'border-box',
            padding: 'var(--lay-pad)'
        }}>
            {children}
        </div>
    );
}

const FAQ = [
    {
        header: 'What is Ambient Sphere?',
        body: 'Ambient Sphere is an audio streaming platform specifically designed for tabletop role-playing games and immersive storytelling. We aim to provide Game Masters with a tool that allows them to easily create rich audio environments that perfectly suit every situation in their game.'
    },
    {
        header: 'Why should I join the community now?',
        body: 'Ambient Sphere is in its very early stages. As a result, the platform itself doesn’t allow for much community interaction as of yet. However, joining now gives you all the more chances to affect where this journey is going. We want to involve you in the process, let you participate where its possible and ultimately share this adventure with you.'
    },
    {
        header: 'How much does it cost?',
        body: 'The current Alpha version is completely free, as we want to hear your feedback while we tailor this tool for the needs of the community. Once the product reaches a sufficient state, we plan to transition to a subscription based model. This enables us to expand our audio library and continuously develop our service – while also being able to pay our bills.'
    },
    {
        header: 'Will Ambient Sphere work on my device?',
        body: 'Ambient Sphere is currently being developed as a web application. It should run on any modern browser on notebooks and desktop computers. Due to technical limitations, we are unable to support mobile devices as of yet (feel free to try, though). Once we grow, we plan to officially support mobile devices as well by providing custom apps in their respective app stores.'
    },
    {
        header: 'When will I be able to create Atmospheres for my own adventures?',
        body: 'The editor is the biggest feature of the whole platform. It is quite complex and has to be integrated well with a lot of different parts of our service. Therefore it takes a lot of time and effort to develop it. Sadly, we have to currently prioritize other parts of the service. Rest assured, however, that we deem the editor as a central piece of our platform and it will arise as soon as we have the capacity to implement it.'
    },
    {
        header: 'Will it be possible to play remotely?',
        body: 'The current Alpha version unfortunately doesn’t support remote play yet. However, the feature is on our road-map. In the full release version, you should be able to let your players listen in to your Ambient Sphere session.'
    },
    {
        header: 'Can I stream the audio to an online streaming service like Twitch, YouTube, Facebook, etc.?',
        body: 'We have plans to provide support for using Ambient Sphere in actual-play streams. The plan is to provide stream-ready audio packages and atmospheres on our platform, that are safe to use on stream. However, the implementation hinges on individual audio licensing conditions. Currently we are unable to state a specific time frame for the arrival of such features.'
    },
    {
        header: 'I love the idea, can I work for Ambient Sphere?',
        body: <span>There is so much to do, so we are always grateful for helping hands. Feel free to contact us at  <a href='mailto:contact@ambientsphere.com' style={{font: 'var(--font-body-sm)', fontWeight: 'bold'}}>contact@ambientsphere.com</a></span>
    }
];

export default function FAQSection({ numColumns }) {
    const faq_expand = useEventTracker({id: "faq_expand"});
    const faqs = FAQ.map((faq,i) => 
            <div key={i}>
                <CollapsableComponentIndex 
                    header={<span style={{color: 'var(--col-fg-text-hint)'}}>{faq.header}</span>}
                    onChange={(state) => {if(state) faq_expand({"question_id": i});} }>
                    <QuestionBody>{faq.body}</QuestionBody>
                </CollapsableComponentIndex>
                <Separator/>
            </div>
        );
    return (
        <>
            <h1 style={{textAlign: 'center'}}>Frequently Asked Questions</h1>
            <div style={{maxWidth: 'calc(3*var(--lay-column-width))', margin: '0px auto'}}>
                <Separator/>
                {faqs}
            </div>
        </>
    );    
}