export const LEGAL_LINKS = {
    imprint: "https://ambientsphere.com/site-notice",
    terms_of_service: "https://ambientsphere.com/terms-of-service",
    privacy_policy: "https://ambientsphere.com/privacy",
};

export const SOCIAL_LINKS = {
    discord: "https://discord.gg/NQyKcaS7CW",
    facebook: "https://fb.me/AmbientSphereSoftware",
    instagram: "https://www.instagram.com/ambient_sphere/",
    linkedin: "https://www.linkedin.com/company/ambientsphere/",
    youtube: "https://www.youtube.com/@AmbientSphereTTRPG"
};

export const APP_LINKS = {    
    signup: "https://app.ambientsphere.com/signup",
    login: "https://app.ambientsphere.com/login"
}