import { useCallback, useEffect, useRef, useState } from 'react';
import { Wrench } from '@phosphor-icons/react';
import { APP_LINKS } from '../constants/links';
import LandingPageFooter from '../components/shared/landing-page-footer/landing_page_footer';
import TopSection from './plans_section/top';
import VideoSection from './sections/video_section';
import useTitle from '../hooks/useTitle';
import CardsSection from './plans_section/cards';
import PlansSection from './plans_section/plans';
import ComparePlansSection from './plans_section/compare_plans';
import Buttons from '../components/shared/buttons/buttons';
import TOP_IMAGE from '../assets/images/subscription_plans_landing_transparent_portal.webp';
import './plans.css';
import useEventTimer from '../hooks/analytics/useEventTimer';
import { useLocation } from 'react-router-dom';
import useEventTracker from '../hooks/analytics/useEventTracker';

function Background() {
    const style = {
        height: "85vh"
    };
    return (
        <div className='thank-you-background-container' style={style}>
            <div className='thank-you-background-image' style={{backgroundImage: "url(" + TOP_IMAGE + ")"}}/>
            <div className='thank-you-background-gradient'/>
        </div>
    );
}

function Separator() {
    return <div style={{height: '140px'}}/>
}

const pageWidthPadding = 48;

export function PlansPage() {
    const { hash } = useLocation();
    const [clientWidth, setClientWidth] = useState(0); 
    const [numColumns, setNumColumns] = useState(1);
    const [containerRef, setContainerRef] = useState(null);
    useTitle('Ambient Sphere | Subscription Plans');
    const updateClientWidth = useCallback((cw) => {
        setClientWidth(cw);
        const nc = Math.ceil((clientWidth - pageWidthPadding) / 320);
        setNumColumns(nc > 4 ? 4 : nc);
    }, [setClientWidth, clientWidth, setNumColumns]);
    const updateContainerRef = useCallback((nr) => {
        setContainerRef(nr);
        updateClientWidth(nr ? nr.clientWidth : 0);
    }, [setContainerRef, updateClientWidth]);
    const updateWidth = useCallback(() => {
        if (containerRef) {
            updateClientWidth(containerRef.clientWidth);
        }
    }, [containerRef, updateClientWidth]);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [updateWidth]);

    const plansScrollRef = useRef(null);
    const compareScrollRef = useRef(null);

    const plansScrollHandler = (e) => {
        e.preventDefault();
        plansScrollRef.current?.scrollIntoView({behavior: "smooth", block: "start"});
    }

    const compareScrollHandler = (e) => {
        e.preventDefault();
        compareScrollRef.current?.scrollIntoView({behavior: "smooth", block: "start"});
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if(hash === '#p')
            setTimeout(() => plansScrollRef.current?.scrollIntoView({behavior: "smooth", block: "start"}), 300);
    }, [hash, plansScrollRef])

    const page_tracker = useEventTimer({id: "page_view_plans"}, 120, true);
    useEffect(() => {
        page_tracker.start && page_tracker.start();
        return () => page_tracker.stop && page_tracker.stop();
    }, [page_tracker]);

    return (
        <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <TopSection numColumns={numColumns} actionCallback={plansScrollHandler}>
                <VideoSection numColumns={numColumns}/>
            </TopSection>
            <Separator/>
            <CardsSection numColumns={numColumns}/>
            <Separator/>
            <div className='page-width-container' ref={updateContainerRef}>
                <PlansSection ref={plansScrollRef} numColumns={numColumns} moreDetailCallback={compareScrollHandler}/>
                <Separator/>
                <ComparePlansSection ref={compareScrollRef} numColumns={numColumns}/>
                <Separator/>
            </div>
            <div style={{flexGrow: 1}}/>
            <div style={{backgroundColor: 'var(--col-bg-dp3)'}}>
                <div className='page-width-container'>
                    <LandingPageFooter/>
                </div>
            </div>
        </div>
    );
}

export function PlansThankYouPage() {
    const [clientWidth, setClientWidth] = useState(0); 
    const [numColumns, setNumColumns] = useState(1);
    const [containerRef, setContainerRef] = useState(null);
    useTitle('Ambient Sphere | Thank you for choosing Ambient Sphere');
    const updateClientWidth = useCallback((cw) => {
        setClientWidth(cw);
        const nc = Math.ceil((clientWidth - pageWidthPadding) / 320);
        setNumColumns(nc > 4 ? 4 : nc);
    }, [setClientWidth, clientWidth, setNumColumns]);
    const updateContainerRef = useCallback((nr) => {
        setContainerRef(nr);
        updateClientWidth(nr ? nr.clientWidth : 0);
    }, [setContainerRef, updateClientWidth]);
    const updateWidth = useCallback(() => {
        if (containerRef) {
            updateClientWidth(containerRef.clientWidth);
        }
    }, [containerRef, updateClientWidth]);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [updateWidth]);
    const page_tracker = useEventTimer({id: "page_view_subscribed"}, 120);
    useEffect(() => {
        page_tracker.start && page_tracker.start();
        return () => page_tracker.stop && page_tracker.stop();
    }, [page_tracker]);
    const plans_thanks_create_accout_click = useEventTracker({id: "plans_thanks_create_account_click"});
    return (
        <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{position: 'relative'}}>
                <Background numColumns={numColumns}/>
            </div>
            <div className='page-width-container' ref={updateContainerRef} style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{flexGrow: 1}}/>
                <div style={{height: 'var(--lay-space-v)'}}/>
                <Wrench weight='fill' size={128}/>
                <div style={{
                    borderRadius: 'var(--lay-border-radius)',
                    background: 'var(--col-bg-dp2)',
                    padding: 'var(--lay-pad)',
                    margin: 'var(--lay-space-v) 0px',
                    textAlign: 'center',
                    font: numColumns > 1 ? 'var(--font-body)' : 'var(--font-body-sm)'
                }}>
                    <h2>Thank you for choosing Ambient Sphere!</h2>
                    <div style={{maxWidth: 'calc(2*var(--lay-column-width))'}}>
                        You've just helped us tremendously with assessing the interest in our platform.
                    </div>
                    <div style={{width: '33%', margin: 'var(--lay-gap-v-separate) auto', height: '1px', background: 'var(--col-prim)'}}/>
                    <div style={{maxWidth: 'calc(2*var(--lay-column-width))'}}>
                        Ambient Sphere is currently being built.
                        That means that unfortunately, there is no editor yet - nor do we have "countless" Atmospheres at the ready.
                        That also means we do not (yet) have a paid version - so for now you can try Ambient Sphere for free!
                    </div>
                </div>
                <div style={{position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{position: 'absolute', zIndex: -1, inset: 0, borderRadius: '25%', background: 'var(--col-bg-dp0)', filter: 'blur(16px) opacity(40%)'}}/>
                    <div style={{
                        textAlign: 'center'
                    }}>
                        Help us build the future of immersion by joining the Ambient Sphere Alpha
                    </div>
                    <div style={{height: 'var(--lay-gap-v-separate)'}}/>
                    <Buttons.Primary 
                        onClick={() => plans_thanks_create_accout_click(null, () => {window.location.assign(APP_LINKS.signup)})}>
                            Sign up for free
                    </Buttons.Primary>
                </div>
                <div style={{height: 'var(--lay-space-v)'}}/>
                <div style={{flexGrow: 1}}/>
            </div>
            <div style={{backgroundColor: 'var(--col-bg-dp3)'}}>
                <div className='page-width-container'>
                    <LandingPageFooter/>
                </div>
            </div>
        </div>
    );
}