import { SOCIAL_LINKS } from "../../constants/links";
import SocialIcons from "../../components/shared/social-icons/social_icons";
import { PrimaryButton } from "../../components/shared/buttons/buttons";
import useEventTracker from "../../hooks/analytics/useEventTracker";
import { useNavigate } from "react-router-dom";

export default function CommunitySection({ numColumns }) {
    const containerStyle = {
        maxWidth: 'calc(3 * var(--lay-column-width))',
        margin: 'var(--lay-space-v-short) auto',
        display: 'flex',
        gap:"calc(2 * var(--lay-space-h))",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "stretch"
    };
    const contentStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "var(--lay-gap-v-connect)",
        maxWidth: "var(--lay-column-width)",
        textAlign: "center",
    };

    const community_plans_click = useEventTracker({id: "community_plans_click"});
    const community_discord_click = useEventTracker({id: "community_discord_click"});

    //const alpha_invite = () => {
    //    return(
    //        <div style={contentStyle}>
    //            <div>
    //            We have opened the gates for you as early as we could, because we <b>love</b> to hear your feedback.
    //            </div>
    //            <div style={{flexGrow: 1}}/>
    //            <a href={APP_LINKS.signup} target="_self">
    //                <PrimaryButton style={{width: "100%", font: 'var(--font-head-h3)'}}>
    //                    Join for free
    //                </PrimaryButton>
    //            </a>
    //        </div>
    //    );
    //}

    const PremiumPromotion = () => {
        const navigate = useNavigate();
        return(
            <div style={contentStyle}>
                <div>
                    With Ambient Sphere Premium, you gain access to our entire library and can craft the perfect soundtrack for your adventure.                </div>
                <div style={{flexGrow: 1}}/>
                <PrimaryButton onClick={() => {community_plans_click(); navigate('/plans#p');}} style={{width: "100%", font: 'var(--font-head-h3)'}}>
                    Get the full experience!
                </PrimaryButton>
            </div>
        );
    }

    return (
        <>
            <h1 style={{textAlign: 'center'}}>Join the Quest</h1>            
            <div style={containerStyle}>
                <PremiumPromotion/>
                <div style={contentStyle}>
                    <div onClick={() => community_discord_click()} style={{margin: "auto"}}>
                        <SocialIcons.Discord size={3 * 24}/>
                    </div>
                    <div style={{flexGrow: 1}}/>
                    <div>
                        We are establishing our community hub over on <a onClick={() => community_discord_click()} href={SOCIAL_LINKS.discord} target="_blank" rel="noreferrer">Discord</a>.
                        Join us there to embark on this journey together!
                    </div>
                </div>
            </div>
        </>
    );    
}