import { useCallback, useEffect, useRef, useState } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import './component_slideshow.css';

function SlideHandle({left=false, padded, active, onClick}) {
    const cls = 'slide-handle ' + 
        (left ? 'left' : 'right') + 
        (!padded ? ' overlayed' : '') +
        (!active ? ' inactive' : '');
    const caret = left ? 
        <CaretLeft size={24}/> : 
        <CaretRight size={24}/>;
    return (
        <div className={cls} onClick={onClick}>
            { caret }
        </div>
    );
}

function SlideIndicatorHandle({ active, slideIdx, setCurrentSlide }) {
    const onClick = active ? () => { } : () => { setCurrentSlide(slideIdx); };
    return (
        <div onClick={onClick}>
            <div className={'slide-indicator-handle' + (active ? ' active' : '')}/>
        </div>
    );
}

function SlideIndicator({slides, currentSlide, setCurrentSlide}) {
    const indicators = [...Array(slides).keys()].map((i) => 
        <SlideIndicatorHandle key={i} active={currentSlide === i} slideIdx={i} setCurrentSlide={setCurrentSlide}/>
    );
    return (slides <= 1) ? <></> : (
        <div className='slide-indicator'>
            { indicators }
        </div>
    );
}

function SlideComponentContainer({ width, children }) {
    return (
        <div style={{
            position: 'relative',
            minWidth: width + 'px',
            maxWidth: width + 'px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center'
        }}>
            { children }
        </div>
    );
}

function Slideshow({
    componentWidth,
    componentsPerSlide, 
    offset, 
    children
}) {
    // Setup refs layout
    const [compWidth, setCompWidth] = useState(0);    
    const [left, setLeft] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const [containerRef, setContainerRef] = useState(null);
    const [innerRef, setInnerRef] = useState(null);
    const updateClientWidth = useCallback((cw) => {
        const maxCompWidth = cw / componentsPerSlide;
        const compW = (componentWidth === null) ? 
            maxCompWidth : 
            (componentWidth < maxCompWidth ? componentWidth : maxCompWidth);
        setCompWidth(compW);
        setLeft((cw / 2) - (compW * componentsPerSlide / 2));
    }, [setCompWidth, componentsPerSlide, componentWidth]);
    const updateContainerRef = useCallback((nr) => {
        setContainerRef(nr);
        updateClientWidth(nr ? nr.clientWidth : 0);
    }, [setContainerRef, updateClientWidth]);
    const updateInnerRef = useCallback((nr) =>{
        setInnerRef(nr);
        setClientHeight(nr ? nr.clientHeight : 0);
    }, [setInnerRef, setClientHeight, children]);
    const onWindowResize = useCallback(() => {
        if (containerRef) {
            updateClientWidth(containerRef.clientWidth);
        }
        if (innerRef) {
            setClientHeight(innerRef.clientHeight);
        }
    }, [updateClientWidth, containerRef, setClientHeight, innerRef]);
    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
    }, [onWindowResize]);
    // Compute
    const pxOffset = offset * compWidth;
    const components = children.map((c, i) => <SlideComponentContainer key={i} width={compWidth}>{c}</SlideComponentContainer>);    
    const slideWidth = compWidth * componentsPerSlide;
    return (
        <div ref={updateContainerRef} style={{
            position: 'relative',
            flexGrow: 1,
            height: clientHeight + 'px'
        }}>
            <div style={{
                position: 'absolute',
                width: slideWidth + 'px',
                height: clientHeight + 'px',
                left: left + 'px',
                overflow: 'hidden'
            }}>
                <div className='slideshow' ref={updateInnerRef} style={{
                    position: 'absolute',
                    left: '-' + pxOffset + 'px',
                    width: slideWidth + 'px',
                }}>
                    { components }
                </div>
            </div>
        </div>
    );    
}

function computeNumSlides(comps, compsPerSlide, stride) {
    if (comps <= 0 || compsPerSlide <= 0 || stride <= 0)
        return 0;
    const overhang = (comps > compsPerSlide) ? comps - compsPerSlide : 0;
    const overSlides = (overhang > 0) ? Math.ceil(overhang / stride) : 0;
    return 1 + overSlides;
}

function computeOffset(numSlides, currentSlide, numComps, compsPerSlide, stride) {
    if (numSlides === 1)
        return 0;
    const originalStride = currentSlide * stride;
    if (currentSlide + 1 < numSlides)
        return originalStride;
    return numComps - compsPerSlide;
}

class SlideInterval {
    constructor () {
        this.cb = null;
        this.interval = null;
        this.dur = 0;
        this.resetCtr = 0;
    }
    #intervalMethod() {
        if (this.resetCtr > 0) {
            --this.resetCtr;
        }
        else if (this.cb) {
            this.cb();
        }
    }
    reset() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(this.#intervalMethod.bind(this), this.dur);
        this.resetCtr = 1;
    }
    stop() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
    started() {
        return this.interval !== null;
    }
    start(dur) {
        if (this.interval !== null) {
            this.stop();
        }
        this.dur = dur;
        this.interval = setInterval(this.#intervalMethod.bind(this), dur);
        this.resetCtr = 0;
    }
    setCb(cb) {
        this.cb = cb;
    }
}

const slideInterval = new SlideInterval();

export default function ComponentSlideshow({
    style,
    children, 
    componentWidth=null,
    componentsPerSlide=1, 
    slideStride=1,
    slideHandlePadded=false,
    roll=true,
    scroll=true,
    autoSlide=null
}) {
    // State
    const numSlides = computeNumSlides(children.length, componentsPerSlide, slideStride);
    const [currentSlide, setCurrentSlide] = useState(0);
    const updateCurrentSlide = (slide) => {
        setCurrentSlide(slide);
        if (autoSlide) {
            slideInterval.reset();
        }
    }
    // Setup scroll
    const scrollRef = useRef();
    const mouseWheelScroll = useCallback((e) => {
        e.preventDefault();
        if (e.deltaY > 0) {
            if (roll) {
                updateCurrentSlide((currentSlide + 1) % numSlides);
            }
            else if (currentSlide + 1 < numSlides) {
                updateCurrentSlide(currentSlide + 1);
            }
        }
        else if (e.deltaY < 0) {
            if (roll && currentSlide === 0) {
                updateCurrentSlide(numSlides - 1);
            }
            else {
                updateCurrentSlide(currentSlide - 1);
            }
        }
    }, [roll, currentSlide, updateCurrentSlide, numSlides]);
    useEffect(() => {
        if (scroll) {
            const target = scrollRef.current;
            target.addEventListener('wheel', mouseWheelScroll, { passive: false });
            return () => {
                target.removeEventListener('wheel', mouseWheelScroll, { passive: false });
            }
        }
    }, [mouseWheelScroll, scroll]);
    // Setup interval
    useEffect(() => {
        if (autoSlide) {
            slideInterval.start(autoSlide);
            return () => slideInterval.stop();
        }
    }, [autoSlide]);
    useEffect(() => {
        if (autoSlide) {
            const interval = () => setCurrentSlide((currentSlide + 1) % numSlides);
            slideInterval.setCb(interval);
        }
    }, [autoSlide, currentSlide, setCurrentSlide, numSlides])
    // Slide handles
    const slideLeftActive = !roll ? (currentSlide > 0) : true;
    const slideRightActive = !roll ? (currentSlide + 1 < numSlides) : true;
    const slideLeft = () => { 
        if (roll && currentSlide === 0)
            updateCurrentSlide(numSlides - 1);
        else if (slideLeftActive)
            updateCurrentSlide(currentSlide - 1);
    };
    const slideRight = () => { 
        if (roll && currentSlide + 1 === numSlides)
            updateCurrentSlide(0);
        else if (slideRightActive)
            updateCurrentSlide(currentSlide + 1);
    };
    // Component
    return (
        <div className='component-slideshow' style={style} ref={scrollRef}>
            <div style={{position: 'relative', display: 'flex'}}>
                <SlideHandle left={true} padded={slideHandlePadded} active={slideLeftActive} onClick={slideLeft}/>
                <Slideshow 
                    componentWidth={componentWidth} 
                    componentsPerSlide={componentsPerSlide}
                    offset={computeOffset(numSlides, currentSlide, children.length, componentsPerSlide, slideStride)}
                >
                    { children }
                </Slideshow>
                <SlideHandle left={false} padded={slideHandlePadded} active={slideRightActive} onClick={slideRight}/>
            </div>
            <SlideIndicator slides={numSlides} currentSlide={currentSlide} setCurrentSlide={updateCurrentSlide}/>
        </div>
    );    
}