import { Check, Sparkle, StarFour } from '@phosphor-icons/react';
import { APP_LINKS } from '../../constants/links';
import Buttons from '../../components/shared/buttons/buttons';
import { forwardRef } from 'react';
import './plans.css';
import useToggleTracker from '../../hooks/analytics/useToggleTracker';
import { useInView } from 'react-intersection-observer';
import useEventTracker from '../../hooks/analytics/useEventTracker';
import { AB_TEST_PLANS_VARIATIONS } from '../../constants/analytics';
import useABTesting from '../../hooks/analytics/useABTesting';
import { useNavigate } from 'react-router';


const casual_storyteller_icon =  <StarFour size={32} color="var(--col-prim)" weight="bold"/>;
const master_narrator_icon = <Sparkle size={32} color="var(--col-prim)" weight="bold"/>;

function PriceTag({ price, time }) {
    const full = Math.floor(price / 100);
    const cents = price - (full * 100);
    return (
        <div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'flex-end'}}>
                <div style={{fontSize: '2rem'}}>€{full}</div>
                <div style={{fontSize: '1.4rem', paddingBottom: '0.6rem'}}>.{cents} / {time}</div>
            </div>
            <div style={{width: '100%', textAlign: 'center', fontSize: '0.7rem', color: 'var(--col-fg-text-hint)'}}>
                VAT INCLUSIVE
            </div>
        </div>
    );
}

function Benefits({benefits}){
    const Benefit = ({children}) => 
        <div style={{display: 'flex', alignItems: "flex-start", textAlign: "left", gap: "var(--lay-gap-h-connect)"}}> 
            <Check style={{flexShrink: "0"}} size={16} weight='bold'/> 
            <span>
                {children}
            </span>
        </div>;
    return(
        <div style={{width: '100%', display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "var(--lay-gap-v-separate)", margin: "var(--lay-space-v) 0", fontSize: "var(--fs-elem-sm)"}}>
            {benefits.map( (b,i) => <Benefit key={i}>{b}</Benefit>)}
        </div>
    );
}

function PrimaryBadge() {
    return (
        <div className='primary-badge'>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', userSelect: 'none'}}>
                <strong>Full experience!</strong>
            </div>
        </div>
    );
}

function SinglePlan({ icon, name, subtitle = null, price, time, primary = false, benefits=[], onMoreDetail = null, version}) {
    const navigate = useNavigate();
    const subtitleComp = subtitle === null ? <div style={{height: '1.32rem'}}/> : (
        <div style={{width: '100%', height: '1.32rem', textAlign: 'center', font: 'var(--font-elem-lg)'}}>
            ({subtitle})
        </div>
    );
    const badgeComp = primary === false ? <></> : <PrimaryBadge/>;
    const iconComp = icon === "low_tier" ? casual_storyteller_icon : 
        (icon === "high_tier" ?  master_narrator_icon : <></> );
    const subscribe_click = useEventTracker({ id: "plans_subscribe_click", data: { ABTestVersion: version} } );
    const detail_click = useEventTracker({ id: "plans_detail_click", data: { ABTestVersion: version} } );
    return (
        <div style={{position: 'relative', overflow: 'hidden'}}>
            {badgeComp}
            <div className='subscription-plan-container' style={{
                width: primary ? "300px" : "260px",
                height: primary ? "600px" : "560px",
                padding: primary ? "var(--lay-pad-wide)" : "var(--lay-pad)",
            }}>
                <div style={{margin: '0 auto'}}>
                    {iconComp}
                </div>
                <h3 style={{textAlign: 'center'}}>{name}</h3>
                {subtitleComp}
                <div style={{height: 'var(--lay-space-h-short)'}}/>
                <PriceTag price={price} time={time}/>
                <Benefits benefits={benefits}/>
                <div style={{flexGrow: 1}}/>
                <a onClick={(e) => { detail_click({name, price}); onMoreDetail(e);}}
                    href='#compare-plans-in-detail' 
                    style={{marginBottom: '4px', color: 'var(--col-fg-text-hint)', font: 'var(--font-body-sm)', fontWeight: 'normal'}}>
                    Click to learn more
                </a>
                <Buttons.Primary onClick={() => {subscribe_click({name, price}); navigate("/thank-you")}} style={primary ? {height: '4rem'} : {}}><strong>Subscribe</strong></Buttons.Primary>
            </div>
        </div>
    );
}

export function SinglePlanFactory({ e, c, v }) {
    return <SinglePlan icon={e.icon} name={e.name} subtitle={e.subtitle} price={e.price} time={e.time} primary={e.primary} benefits={e.benefits} onMoreDetail={c} version={v}/>;
}

const PlansSection = forwardRef(function PlansSection({numColumns, moreDetailCallback}, ref) {
    const selectedVariation = useABTesting().plans;
    const variations = AB_TEST_PLANS_VARIATIONS;
    const plansComp = numColumns > 2 ?
        variations[selectedVariation].map((e, i) => <SinglePlanFactory e={e} c={moreDetailCallback} v={selectedVariation} key={i}/>)
        :
        [1,0,2].map((idx, i) => <SinglePlanFactory e={variations[selectedVariation][idx]} c={moreDetailCallback} v={selectedVariation} key={i}/>);
    
    const plans_section = useToggleTracker({ id: "plans_section", data: {ABTestVersion: selectedVariation}}, {on:"_enter", off: "_leave"}, 2);
    const onChange = (inView, entry) => {
        if(inView)
            plans_section.start()
        else
            plans_section.stop();
    }
    const view  = useInView({
        threshold: 0, 
        onChange
    })
    const free_account_click = useEventTracker({id: "plans_free_account_click", data: {ABTestVersion: selectedVariation}});

    return (
        <div ref={ref} style={{textAlign: "center", padding: "var(--lay-space-v-wide) 0"}}>
            <h2>Get more with Premium</h2>
            <div ref={view.ref} style={{display: 'flex', gap: '24px', alignItems: 'center', margin: 'var(--lay-space-v) 0', flexDirection: numColumns > 2 ? 'row' : 'column'}}>
                <div style={{flexGrow: 1}}/>
                {plansComp}
                <div style={{flexGrow: 1}}/>
            </div>
            or create a <span className="link" onClick={() => free_account_click(null, window.location.assign(APP_LINKS.signup, {replace: true}))}>
                free account
            </span> and try it out.
        </div>
    );
});
export default PlansSection;