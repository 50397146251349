import CARDS_IMAGE from '../../assets/images/cards.webp';

function Background({numColumns}) {
    const height = 
        numColumns === 4 ? '228px' :
        numColumns === 3 ? '198px' :
        numColumns === 2 ? '168px' :
        '138px';
    return (
        <div style={{position: 'relative', margin: '16px 0px'}}>
            <div style={{
                margin: '0px auto',
                width: '100%',
                maxWidth: '3008px',
                height: height,
                backgroundImage: 'url(' + CARDS_IMAGE + ')',
                backgroundrepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundSize: 'cover'
            }}/>
        </div>
    );
}

export default function CardsSection({numColumns}) {
    const wrapperMax = "calc(" + numColumns + " * var(--lay-column-width))";
    const textFont = numColumns > 1 ? "var(--font-body)" : "var(--font-body-sm)";
    return (
        <div style={{padding: '16px 0px', background: '#0004'}}>
            <div style={{margin: '0px auto', textAlign: 'center', maxWidth: wrapperMax}}>
                <h4 style={{color: 'var(--col-prim-elem)'}}><i>"A thousand Atmospheres, a thousand stories."</i></h4>
                <div style={{font: textFont}}>Subscribing gives you access to our whole library containing Atmospheres made by Ambient Sphere and our community.</div>
            </div>
            <Background numColumns={numColumns}/>
        </div>
    );
}