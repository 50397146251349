import { useCallback, useEffect, useState } from 'react';
import LandingPageFooter from '../components/shared/landing-page-footer/landing_page_footer';
import FeatureSection from './sections/feature_section';
import FAQSection from './sections/faq_section';
import CommunitySection from './sections/community_section';
import CompanySection from './sections/company_section';
import HeroSection from './sections/hero_section';
import ContentSection from './sections/content_section';
import VideoSection from './sections/video_section';
import VisionSection from './sections/vision_section';
import useTitle from '../hooks/useTitle';
import './landing_page.css';
import useEventTimer from '../hooks/analytics/useEventTimer';

function Separator() {
    return <div style={{height: '140px'}}/>
}

const pageWidthPadding = 48;

export default function LandingPage() {
    const [clientWidth, setClientWidth] = useState(0); 
    const [numColumns, setNumColumns] = useState(1);
    const [containerRef, setContainerRef] = useState(null);
    useTitle('Ambient Sphere | Bring your story to life');
    const updateClientWidth = useCallback((cw) => {
        setClientWidth(cw);
        const nc = Math.ceil((clientWidth - pageWidthPadding) / 320);
        setNumColumns(nc > 4 ? 4 : nc);
    }, [setClientWidth, clientWidth, setNumColumns]);
    const updateContainerRef = useCallback((nr) => {
        setContainerRef(nr);
        updateClientWidth(nr ? nr.clientWidth : 0);
    }, [setContainerRef, updateClientWidth]);
    const updateWidth = useCallback(() => {
        if (containerRef) {
            updateClientWidth(containerRef.clientWidth);
        }
    }, [containerRef, updateClientWidth]);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [updateWidth]);

    const page_tracker = useEventTimer({id: "page_view_home"}, 120);
    useEffect(() => {
        page_tracker.start && page_tracker.start();
        return () => page_tracker.stop && page_tracker.stop();
    }, [page_tracker]);

    return (
        <div style={{minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <HeroSection numColumns={numColumns}>
                <VideoSection numColumns={numColumns}/>
            </HeroSection>       
            <div className='page-width-container' ref={updateContainerRef}>
                <Separator/>
                <VisionSection numColumns={numColumns}/>
                <Separator/>
                <FeatureSection numColumns={numColumns}/>
                <Separator/>
                <ContentSection numColumns={numColumns}/>
                <Separator/>
                <CommunitySection numColumns={numColumns}/>
                <Separator/>
                <CompanySection numColumns={numColumns}/>
                <Separator/>
                <FAQSection numColumns={numColumns}/>
                <Separator/>
            </div>
            <div style={{flexGrow: 1}}/>
            <div style={{backgroundColor: 'var(--col-bg-dp3)'}}>
                <div className='page-width-container'>
                    <LandingPageFooter/>
                </div>
            </div>
        </div>
    );
}