import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo_gradient.svg';
//import UserControl from './user_control';
//import Tabs from './tabs';
//import { useSelector } from 'react-redux';
import { SecondaryButton } from '../../../components/shared/buttons/buttons';
import { APP_LINKS } from '../../../constants/links';
import './header.css';
import useEventTracker from '../../../hooks/analytics/useEventTracker';

export default function Header() {
    const trackButtonClick = useEventTracker({id: "signin_button_click"});
    
    const Logo = (
        <img src={logo} alt="" style={{border: '4px solid transparent', boxSizing: 'border-box', height: '100%', width: 'var(--size-header-height)'}}/>
    );
    return (
        <div className='depr-header'>
            <Link to={'/'} aria-label='Homepage'>
                { Logo }
            </Link>
            <div style={{flexGrow: 1}}/>
            <SecondaryButton onClick={() => trackButtonClick(null, window.location.assign(APP_LINKS.login, {replace: true}))} style={{
                margin: "var(--lay-pad-slim) var(--lay-pad)",
                fontSize: "var(--fs-elem-sm)"}}>Sign in</SecondaryButton> 
            
        </div>
    );
}
