import React from 'react';
import App from './app';
import { createRoot, hydrateRoot  } from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { BrowserRouter } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}

const rootElement = document.getElementById('root');
const root = (rootElement.hasChildNodes()) ?
    hydrateRoot(rootElement, <BrowserRouter><App/></BrowserRouter>) :
    createRoot(rootElement);
root.render(<BrowserRouter><App/></BrowserRouter>);
