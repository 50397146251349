import './hero_section.css';
import { PrimaryButton } from '../../components/shared/buttons/buttons';
//import { APP_LINKS } from '../../constants/links';
import HERO_IMAGE from '../../assets/images/bg_portal.webp';
import useEventTracker from '../../hooks/analytics/useEventTracker';
import { useNavigate } from 'react-router-dom';

function Background() {
    return (
        <div className='background-container'>
            <div className='background-image' style={{backgroundImage: "url(" + HERO_IMAGE + ")"}}/>
            <div className='background-gradient'/>
        </div>
    );
}

export default function HeroSection({numColumns, children}){
    const titleSize = numColumns >= 4 ? "65px" : 
        numColumns === 3 ? "45px" : 
        numColumns === 2 ? "35px" : 
        "1.9rem";
    const navigate = useNavigate();
    const wrapperMaxWidth = "calc(" + numColumns +" * var(--lay-column-width))";
    const trackButtonClick = useEventTracker({id: "hero_button_click"});
    const onActionClick = (e) => {
        trackButtonClick();
        navigate("/plans#p");
        //window.location.href = APP_LINKS.signup;
    }

    return (
        <div className='hero-section'>
            <Background/>
            <div className='wrapper' style={{maxWidth: wrapperMaxWidth}}>
                <div className='title'>
                    <div style={{flexGrow: 1}}/>
                    <div style={{
                        margin: "8px 0px",
                        font: "var(--font-head-h1)",
                        fontSize: titleSize}}>
                        <strong>Bring your story to life</strong>
                    </div>
                    <h4>
                        Experience the dawn of a brand-new community driven TTRPG audio immersion tool
                    </h4>
                    <div style={{margin: "var(--lay-gap-v-connect)"}}/>
                    <PrimaryButton onClick={onActionClick} style={{ minWidth: "175px" }}>Get Started</PrimaryButton>
                    <div style={{flexGrow: 1}}/>
                </div>
                <div>
                    { children }
                </div>
            </div>
        </div>
    );
}