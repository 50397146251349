import TeamImage from '../../assets/images/team.webp';
import './company_section.css';

const Story = [
    "The only thing we love more than hearing a good story is creating one. That’s why we cherish tabletop role-playing so much. We always wanted to bring storytelling to the next level. Audio proved to be the right tool for the job. That’s how the idea of Ambient Sphere was born.",
    "But we wanted more. We wanted to give something back to the wonderful role-playing community. Why not pass the power to create immersive soundtracks to you? We want everyone to be able to create just the perfect ambiance for their story. We want everyone to enjoy the magic that is audio. To bring every story to life.",
    "We dedicate this to you."
];

export default function CompanySection({ numColumns }) {
    const story = Story.map((p,i) => <p key={i} style={{margin: 0}}>{p}</p>);
    const textStyle = {
        gap: 'var(--lay-gap-v-separate)',
        padding: (numColumns === 4) ? 'var(--lay-space-v) var(--lay-pad-wide)' : 'var(--lay-pad-wide)',
        ...(numColumns === 3 ? {
            maxWidth: 'calc(2*var(--lay-column-width))',
            margin: '0px auto'
        } : {})
    };
    const imageStyle = {
        padding: (numColumns > 1 ? '0px var(--lay-pad-wide)' : '0px var(--lay-pad-compact)'),
        ...(numColumns === 3 ? {
            maxWidth: 'calc(2*var(--lay-column-width))',
            margin: '0px auto'
        } : {})
    };
    const containerStyle = {
        gap:
            numColumns === 4 ? 'var(--lay-space-h-wide)' :
            numColumns === 1 ? '0px' :
            'var(--lay-space-v-short)',
        flexDirection:
            numColumns > 3 ? 'row' :
            'column'
    }
    return (
        <>
            <h1 style={{textAlign: 'center'}}>About Us</h1>
            <div className='company-message' style={containerStyle}>
                <div className='company-message-box' style={textStyle}>
                    {story}
                    <span style={{color: 'var(--col-prim)'}}>~ the party</span>
                </div>
                <div className='company-message-box' style={imageStyle}>
                    <div style={{flexGrow: 1}}/>
                    <img src={TeamImage} alt="" style={{width: '100%', borderRadius: '4px', aspectRatio: '1280 / 712'}}/>
                </div>
            </div>
        </>
    );    
}