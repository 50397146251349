import { useState } from "react";
import { getLegalPageContent } from "../api/cms";
import { useEffect } from "react";
import useTitle from "../hooks/useTitle";
import LandingPageFooter from "../components/shared/landing-page-footer/landing_page_footer";
import HTMLContent from "../deprecated/components/html-content/html_content";
import './legal_page.css';

function LegalPage({head, children}){
    return(
        <div style={{minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className="page-width-container">
                <div style={{height: "var(--lay-space-v)"}}/>
                    <h1>{head}</h1>
                    {children}
                <div style={{height: "var(--lay-space-v)"}}/>
            </div>
            <div style={{flexGrow: 1}}/>
            <div style={{backgroundColor: 'var(--col-bg-dp3)'}}>
                <div className='page-width-container'>
                    <LandingPageFooter/>
                </div>
            </div>
        </div>
    )
}

export function LegalCMSWrapper({type}) {
    const [content, setContent] = useState();
    useEffect(() => {
        let isMounted = true;
        const request = getLegalPageContent(type, 
            (data) => (isMounted && setContent(data)))
        return () => {
            isMounted = false;
            request?.controller?.abort();
        }
    }, [type]);
    useTitle(content?.meta_page_title);
    return (
        <LegalPage head={content?.head}>
            <HTMLContent html={content?.body}/>
        </LegalPage>
    );
}

export function ImprintPage(){
    return(
        <LegalCMSWrapper type="imprint"/>
    )
}

export function PrivacyPage(){
    return(
        <LegalCMSWrapper type="privacy"/>
    )
}

export function TermsOfService(){
    return(
        <LegalCMSWrapper type="terms"/>
    )
}

export function SocialMediaPrivacyPage(){
    return(
        <LegalCMSWrapper type="social-media-privacy"/>
    )
}