import './image_card.css'

export default function ImageCard({style, src, children}) {
    return (
        <div className='image-card'>
            <div className='card-image'>
                <img style={{...style, width: '100%', display: 'block'}} alt='' src={src}/>
            </div>
            <div className='card-overlay'>
                { children }
            </div>
        </div>
    );
}