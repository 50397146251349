import { useState } from "react";
import { useEffect } from "react";
import { getArtistList } from "../../api/cms";
import ComponentSlideshow from "../../components/shared/component-slideshow/component_slideshow";
import './content_section.css';
import useEventTracker from "../../hooks/analytics/useEventTracker";

function Artist({children, img, url}) {
    const content_artist_link = useEventTracker({ id: "content_artist_link" });
    return (
        <a className="content-section-artist" onClick={() => content_artist_link({artist: children})} href={url} target="_blank" rel="noreferrer">
            <div style={{
                    width: '128px', 
                    height: '128px', 
                    borderRadius: '256px', 
                    backgroundColor: '#223', 
                    marginBottom: '8px',
                    backgroundImage: 'url(' + img + ')',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "100%"
                    }}/>
            <div style={{width: '128px', textAlign: 'center'}}>
                { children }
            </div>
        </a>
    );
}

function ArtistSmall({children, img, url}) {
    const content_artist_link = useEventTracker({ id: "content_artist_link" });
    return (
        <a className="content-section-artist" onClick={() => content_artist_link({artist: children})}  href={url} target="_blank" rel="noreferrer">
            <div style={{
                    width: '72px', 
                    height: '72px', 
                    borderRadius: '256px', 
                    backgroundColor: '#223', 
                    marginBottom: '8px',
                    backgroundImage: 'url(' + img + ')',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "100%"
                    }}/>
            <div style={{width: '72px', textAlign: 'center'}}>
                { children }
            </div>
        </a>
    );
}

const musicCount = 2000;

export default function ContentSection({numColumns}){
    const [artists, setArtists] = useState([]);

    // Setup artists
    useEffect( () => {
        getArtistList((data)=>setArtists(data))
    }, []);
    const ArtistsComponents = artists.map(
        numColumns === 1 ? 
            (artist, key) => <ArtistSmall key={key} img={artist.image} url={artist.url}>{artist.name}</ArtistSmall> :
            (artist, key) => <Artist key={key} img={artist.image} url={artist.url}>{artist.name}</Artist>);
    const componentWidth = (numColumns === 1) ? 88 : 144; 
    const componentsPerSlide = (numColumns === 1) ? 3 : numColumns * 2;

    // Component
    return(
        <>
            <h1 style={{textAlign: 'center'}}>Powered by the Community</h1>
            <div className="content-section">
                <div className="content-section-artists-widget">
                    <div style={{textAlign: 'center', margin: '0px var(--lay-pad)'}}>
                        With over <b>{musicCount}</b> Music tracks by these people
                    </div>
                    <ComponentSlideshow
                        componentsPerSlide={componentsPerSlide}
                        componentWidth={componentWidth}
                        slideStride={2}
                        slideHandlePadded={true}
                        roll={true}
                        autoSlide={3000}
                    >
                        {ArtistsComponents}
                    </ComponentSlideshow>
                </div>
                <div className="content-section-partner">
                    <span style={{font: 'var(--font-head-h4)'}}>Partnership Program for Artists</span>
                    <span style={{font: 'var(--font-body-sm)'}}>
                        The Ambient Sphere audio library is powered by a growing list of artists.
                        We are always looking to expand, with a partnership program that focuses on providing value for everyone involved.
                        If you are an artist and this sounds interesting to you, feel free to contact us:
                    </span>
                    <a href='mailto:partner@ambientsphere.com' style={{
                        font: 'var(--font-body-sm)',
                        fontWeight: 'bold'
                    }}>
                        partner@ambientsphere.com
                    </a>
                </div>
            </div>
        </>
    );
}