import { useLocation } from "react-router-dom";
import { analyticsContext } from "../../contexts/analytics";
import { useEffect } from "react";

export default function useABTesting(){
    const { search } = useLocation();
    const params = search.match(/[a-zA-Z0-9][a-zA-Z0-9]*/g);
    useEffect(() => {
        if(params)
            for(let i = 0; i < params.length; i+=2)
                if(params[i] === 'v')
                    analyticsContext.set_AB_testing_id(params[i+1]);
    }, [params]);
    return analyticsContext.get_AB_testing_id();
}