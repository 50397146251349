import SocialIcons from '../social-icons/social_icons';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import { APP_LINKS } from '../../../constants/links';
import { FullLogo } from '../logo/logo';
import { Link } from 'react-router-dom/dist';
import './landing_page_footer.css';

function LinkListTitle({ children }) {
    return (
        <div style={{
            font: 'var(--font-elem-md)',
            marginBottom: 'var(--lay-pad-compact)',
            color: 'var(--col-fg-text-hint)'
        }}>
            <b>{ children }</b>
        </div>
    );
}

export default function LandingPageFooter() {
    return (
        <div className='lp-footer'>
            <div className='top-grid'>
                <div className='top-grid-c1'>
                    <FullLogo style={{height: 'calc(var(--fs-elem-lg) * var(--lh-mult-head))'}}/>
                </div>
                <div className='top-grid-c2'>
                    <SocialIcons.Discord size={26}/>
                    <SocialIcons.Facebook size={26}/>
                    <SocialIcons.Instagram size={26}/>
                    <SocialIcons.YouTube size={26}/>
                </div>
            </div>
            <div className='separator'/>
            <div className='bottom-grid'>
                <div className='bottom-grid-c1' style={{gap: 'var(--lay-space-h-wide)'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <LinkListTitle>Legal</LinkListTitle>
                        <Link to={PAGE_ROUTES.imprint}>Imprint</Link>
                        <Link to={PAGE_ROUTES.privacyPolicy}>Privacy Policy</Link>
                        <Link to={PAGE_ROUTES.termsOfService}>Terms of Service</Link>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <LinkListTitle>App</LinkListTitle>
                        <Link to={APP_LINKS.login}>Sign in</Link>
                        <Link to={APP_LINKS.signup}>Sign up</Link>
                    </div>
                </div>
                {
                    //<div className='bottom-grid-c2'>
                    //    <Link to={PAGE_ROUTES.status404}>Contact</Link>
                    //    <Link to={PAGE_ROUTES.status404}>Creators</Link>
                    //    <Link to={PAGE_ROUTES.status404}>Investors</Link>
                    //    <Link to={PAGE_ROUTES.status404}>Press</Link>
                    //    <Link to={PAGE_ROUTES.status404}>Jobs</Link>
                    //</div>
                }
                <div className='bottom-grid-c3' style={{display: 'flex'}}>
                    <div style={{flexGrow: 1}}/>
                    <div>&#169; 2023 Ambient Sphere UG</div>
                </div>
            </div>
        </div>
    );
}