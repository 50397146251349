import Header from './deprecated/components/header/header';
import RoutesRoot from './routes/root';
import './app.css';

export default function App() {
    return (
        <div className='app-client'>
            <div className='header-container'>
                <Header/>
            </div>
            <div className='page-container'>
                <RoutesRoot/>
            </div>
        </div>
    );
}