export default function VisionSection({numColumns}){
    const textStyle = {
        margin: "0px auto",
        maxWidth: "calc(2 * var(--lay-column-width))",
        textAlign: "center",
    };
    const quoteStyle = {
        textAlign: "center",
        font: "var(--font-elem-lg)",
        fontStyle: "italic",
        color: "var(--col-fg-text-highlight)"
    };
    const quotationMarkStyle = {
        fontWeight: "bold",
        color: "var(--col-fg-text-hint)"
    };
    const separatorStyle = {
        boxSizing: "border-box",
        margin: "var(--lay-gap-v-separate) auto",
        padding: numColumns > 1 ? "0px var(--lay-space-h-wide)" : "0px calc(2*var(--lay-space-h-wide))",
        maxWidth: "var(--lay-column-width)",
        height: "1px",
    };
    return(
        <>
            <h1 style={{textAlign: 'center'}}>
                Our Quest
            </h1>
            <div style={quoteStyle}>
                <span style={quotationMarkStyle}>&#8220;</span>
                Imagine a world in which every story you tell has the soundtrack it deserves.
                <span style={quotationMarkStyle}>&#8221;</span>
            </div>
            <div style={separatorStyle}><div style={{width: '100%', height: '100%', backgroundColor: "var(--col-prim)"}}/></div>
            <div style={textStyle}>
                Ambient Sphere aims to offer immersive audio for storytelling games.
                We want to empower users to create, customize, and share their own story soundtracks.
                Join our community and help us build a platform that caters to all your audio needs, no matter what genre you play. 
                Ambient Sphere is the place where your story can come to life.
            </div>
        </>
    );
}