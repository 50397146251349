import React from 'react';
import LandingPage from '../pages/landing_page';
import { Routes, Route } from "react-router-dom";
import { ImprintPage, PrivacyPage, SocialMediaPrivacyPage, TermsOfService } from '../pages/legal_page';
import { Status404 } from '../pages/404';
import { PlansPage, PlansThankYouPage } from '../pages/plans';

export default function RoutesRoot() {
    return (
        <Routes>
            <Route path='/' element={<LandingPage/>}/>
            <Route path='/plans' element={<PlansPage/>}/>
            <Route path='/thank-you' element={<PlansThankYouPage/>}/>
            <Route path="/imprint" element={ <ImprintPage/> }/>
            <Route path="/site-notice" element={ <ImprintPage/> }/>
            <Route path="/privacy" element={ <PrivacyPage/> }/>
            <Route path="/terms-of-service" element={ <TermsOfService/> }/>
            <Route path="/social-media-privacy" element={ <SocialMediaPrivacyPage/> }/>
            <Route path='*' element={<Status404/>}/>
        </Routes>
    );
}