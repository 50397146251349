import { useState } from "react";
import { analyticsContext } from "../../contexts/analytics";

export default function useToggleTracker(event, appendix = { on: "", off: ""}, delay = 0){
    const [timer, setTimer] = useState(false);
    const [state, setState] = useState(false);

    const sendEvent = (data, state) => {
        const id = event.id + (state ? appendix.on : appendix.off);
        if(delay > 0)
            analyticsContext.event(id, {...data, ...event.data, delay});
        else
            analyticsContext.event(id, {data, ...event.data});
    }    
    
    const start = (data) => {
        if(delay <= 0) {
            sendEvent(data, true);
            return;
        }
        if(state)
            return;
        setState(true);
        const timerFunc = () => {
            setTimer(false);
            sendEvent(data, true);
        }
        setTimer(setTimeout(timerFunc, delay * 1000));
    }

    const stop = (data) => {
        if(delay <= 0) {
            sendEvent(data, false);
            return;
        }
        if(!state)
            return;
        setState(false);
        if(timer){
            clearTimeout(timer);
            setTimer(false);
        }
        else
            sendEvent(data, false);
    }

    return { start, stop }
}