import { useEffect, useState } from "react";
import { analyticsContext } from "../../contexts/analytics";

export default function useEventTimer(event, interval, addABVersion = false)
{
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        const eventTimer = analyticsContext.get_event_timer( 
            interval,
            event.id,
            event?.data, 
            addABVersion); 
        setTimer(eventTimer);
        return () => eventTimer.stop;
    }, [event.id, event?.data, interval, addABVersion])

    return { start: timer?.start, stop: timer?.stop }
}