import ImageCard from '../../components/shared/image-card/image_card';
import IMAGE_ATMOSPHERE from '../../assets/images/feature_atmosphere.webp';
import IMAGE_DYBAMIC_AUDIO from '../../assets/images/feature_dynamic_audio.webp';
import IMAGE_LIBARAY from '../../assets/images/feature_library.webp';
import './feature_section.css';
import useToggleTracker from '../../hooks/analytics/useToggleTracker';

const Images = {
    atmosphere: IMAGE_ATMOSPHERE,
    dynamicAudio: IMAGE_DYBAMIC_AUDIO,
    library: IMAGE_LIBARAY,
};

function FeatureHeader({children}) {
    return (
        <>
            <h2 style={{margin: 0}}>{children}</h2>
            <div style={{
                flexShrink: 0,
                width: '192px',
                height: '1px',
                margin: 'var(--lay-pad-compact) auto',
                backgroundColor: 'var(--col-prim)'
            }}/>
        </>
    );
}

function Feature({ children, onMouseEnter=undefined, onMouseLeave=undefined }) {
    return (
        <div className='feature-overlay custom-scrollbar'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <div className='feature-container'>
                <div style={{flexGrow: 1}}/>
                {children}
                <div style={{flexGrow: 1}}/>
            </div>
        </div>
    );
}

function OverlayAtmosphere({onMouseEnter=undefined, onMouseLeave=undefined}) {
    return (
        <Feature onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <FeatureHeader>Audio tailored for your game</FeatureHeader>
            <span>
                Managing audio during the game can be tricky as you never know what your players are going to do.
            </span>
            <p>
                With Ambient Sphere, however, you are always prepared.
                Change the entire atmosphere with the single click of a button.
                Each combination can have its own unique playlist, sound effects, and ambiance.
                Thus you never need to worry about the details and can focus on telling your story.
            </p>
        </Feature>
    );
}

function OverlayDynamicAudio({onMouseEnter=undefined, onMouseLeave=undefined}) {
    return (
        <Feature onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <FeatureHeader>Dynamic Audio</FeatureHeader>
            <span>
                With our computer game-inspired audio engine, all sound sets can be carefully crafted and dynamically played. 
                This allows you to fully immerse yourself in a never-repeating atmosphere.
            </span>
        </Feature>
    );
}

function OverlayLibrary({onMouseEnter=undefined, onMouseLeave=undefined}) {
    return (
        <Feature onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <FeatureHeader>Extensive Library</FeatureHeader>
            <span>
                Every story is unique, and so are the audio needs. 
                But don't worry, we've got you covered. 
                With a constantly growing audio library, we aim to provide you with the perfect sound for whatever game you play.
            </span>
        </Feature>
    );
}

export default function FeatureSection({ numColumns }) {
   
    // Tracking
    const feature_hover = useToggleTracker({ id: "feature_hover"}, {on:"_enter", off: "_leave"}, 2);
    
    const atmosphereCard = <ImageCard style={{aspectRatio: '1336 / 690'}} src={Images.atmosphere}>
            <OverlayAtmosphere 
                onMouseEnter={() => feature_hover.start({feature: "Atmosphere"})}
                onMouseLeave={() => feature_hover.stop({feature: "Atmosphere"})}/>
        </ImageCard>;
    const dynamicAudioCard = <ImageCard style={{aspectRatio: '16 / 9'}} src={Images.dynamicAudio}>
            <OverlayDynamicAudio 
                onMouseEnter={() => feature_hover.start({feature: "Audio"})}
                onMouseLeave={() => feature_hover.stop({feature: "Audio"})}/>
        </ImageCard>;
    const libraryCard = <ImageCard style={{aspectRatio: '16 / 9'}} src={Images.library}>
            <OverlayLibrary 
                onMouseEnter={() => feature_hover.start({feature: "Library"})}
                onMouseLeave={() => feature_hover.stop({feature: "Library"})}/>
        </ImageCard>

    const body = (numColumns === 1) ? (
        <div className='feature-section-container'>
            { atmosphereCard }
            { dynamicAudioCard }
            { libraryCard }
        </div>
    ) : (
        <div className='feature-section-container'>
            { atmosphereCard }
            <div className='feature-section-container-l2'>
                { dynamicAudioCard }
                { libraryCard }
            </div>
        </div>
    );
    return (
        <>
            <h1 style={{textAlign: 'center'}}>Discover Ambient Sphere</h1>
            { body }
        </>
    );
}