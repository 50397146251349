import { Check, Users } from '@phosphor-icons/react';
import './compare_plan.css';
import { forwardRef } from 'react';

const check = <Check color='var(--col-prim)' size={25} weight='bold'/>;
const user = (number) => 
    <div style={{display: "flex", flexDirection:"row", gap: "var(--lay-gap-h-connect)"}}>
        {number} <Users color='var(--col-prim)' size={25} weight='bold'/>
    </div>

const compare_table = [
    ["", "Actor (Free)", "Casual Storyteller", "Master Narrator"],
    ["Access up to 3 Atmospheres rotating monthly",                           check, check, check],
    ["Ad-free",                                                              null, check, check],
    ["Unlimited access to the entire library",                               null, check, check],
    ["Get access to additional Quick Actions to layer on top of your sound", null, check, check],
    ["Stream audio online to your party",                                    null, user(4), user(8)],
    ["Build your own Atmospheres from scratch or based on existing ones",    null, null, check],
    ["Add our own Quick Action collections over any Atmosphere",             null, null, check],
    ["Share your creations with the community",                              null, null, check],
];

const ComparePlansSection = forwardRef(function ComparePlansSection({ numColumns }, ref){

    const Field = ({isFirst, children}) => {
        const className = 'plans-compare-column' + (isFirst ? '-category' : '-plan');
        return(
            <td className={className}>
                {children}
            </td>
        );
    }

    const Row = ({row}) => {
        const entry = row.map((f, i) => <Field key={i} isFirst={i===0}>{f}</Field>)
        return (
            <tr className='plans-compare-column'>
                {entry}
            </tr>
        );
    }

    const table = compare_table.map((r, i) => <Row key={i} row={r}></Row> )

    return(
    <div ref={ref} style={{padding: "var(--lay-pad) 0"}}>
        <h2 style={{textAlign: 'center'}}>Compare Plans & Features</h2>
        <table style={{width: "100%", font: numColumns > 1 ? "var(--font-body)" : "var(--font-body-sm)"}}>
            <tbody>
                {table}
            </tbody>
        </table>
    </div>);
});

export default ComparePlansSection;