import DiscordLogo from '../../../assets/icons/brands/discord.svg';
import { FacebookLogo, InstagramLogo, LinkedinLogo, YoutubeLogo } from '@phosphor-icons/react';
import { SOCIAL_LINKS } from '../../../constants/links';
import '../../../styles/theme.css';

function SocialIconLink({link, label, children}) {
    if (link)
        return <a href={link} target='_blank' rel='noreferrer' aria-label={label}>{children}</a>;
    else
        return <span>{children}</span>
}

export function DiscordIcon({ containLink = true, size = 24 }) {
    const link = containLink ? SOCIAL_LINKS.discord : null;
    return (
        <SocialIconLink link={link} label="Discord">
            <img src={DiscordLogo} style={{width: String(size) + 'px', aspectRatio: '128 / 96'}} alt=""/>
        </SocialIconLink>
    );
}

export function FacebookIcon({ containLink = true, size = 24 }) {
    const link = containLink ? SOCIAL_LINKS.facebook : null;
    return (
        <SocialIconLink link={link} label="Facebook">
            <FacebookLogo color='var(--col-fg-icon)' size={size - 2} weight='fill'/>
        </SocialIconLink>
    );
}

export function InstagramIcon({ containLink = true, size = 24 }) {
    const link = containLink ? SOCIAL_LINKS.instagram : null;
    return (
        <SocialIconLink link={link} label="Instagram">
            <InstagramLogo color='var(--col-fg-icon)' size={size}/>
        </SocialIconLink>
    );
}

export function LinkedInIcon({ containLink = true, size = 24 }) {
    const link = containLink ? SOCIAL_LINKS.linkedin : null;
    return (
        <SocialIconLink link={link} label="LinkedIn">
            <LinkedinLogo color='var(--col-fg-icon)' size={size} weight='fill'/>
        </SocialIconLink>
    );
}

export function YouTubeIcon({ containLink = true, size = 24 }) {
    const link = containLink ? SOCIAL_LINKS.youtube : null;
    return (
        <SocialIconLink link={link} label="YouTube">
            <YoutubeLogo color='var(--col-fg-icon)' size={size + 2} weight='fill'/>
        </SocialIconLink>
    );
}

const SocialIcons = {
    Discord: DiscordIcon,
    Facebook: FacebookIcon,
    Instagram: InstagramIcon,
    LinkedIn: LinkedInIcon,
    YouTube: YouTubeIcon
};
export default SocialIcons;