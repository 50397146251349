import { useCallback, useEffect, useState } from 'react';
import { Minus, Plus } from '@phosphor-icons/react';
import './collapsable_component_index.css';

function CollapseButton({ collapsed }) {
    return (
        <div className='collapsable-component-index-button'>
            { collapsed ? <Plus/> : <Minus/> }
        </div>
    );
}

export default function CollapsableComponentIndex({ header, onChange = undefined, children }) {
    // Collapse state
    const [collapsed, setCollapsed] = useState(true);
    const onHeaderClick = () => {
        onChange && onChange(collapsed);
        setCollapsed(!collapsed);
    }
    // Inner height
    const [innerHeight, setInnerHeight] = useState(0);
    const [innerRef, setInnerRef] = useState(null);
    const updateInnerRef = useCallback((nr) => {
        setInnerRef(nr);
        setInnerHeight(nr ? nr.clientHeight : 0);
    }, [setInnerRef, setInnerHeight]);
    const updateWidth = useCallback(() => {
        if (innerRef) {
            setInnerHeight(innerRef.clientHeight);
        }
    }, [innerRef, setInnerHeight]);
    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [updateWidth]);
    const containerHeight = collapsed ? 0 : innerHeight;
    // Component
    return (
        <>
            <div className='collapsable-component-index-header' onClick={onHeaderClick}>
                <div style={{flexGrow: 1}}>{header}</div>
                <CollapseButton collapsed={collapsed}/>
            </div>
            <div className='collapsable-component-index-container' style={{height: containerHeight + 'px'}}>
                <div className='collapsable-component-index-card'>
                    <div ref={updateInnerRef}>
                        { children }
                    </div>
                </div>
            </div>
        </>
    );
}